import * as React from 'react';
import { useState } from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import { useNavigate } from 'react-router-dom';
import Sheet from '@mui/joy/Sheet';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Box from '@mui/joy/Box';
import Modal from '@mui/joy/Modal';
import Chip from '@mui/joy/Chip';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './TwoSidedLayout.tsx';
import logo from '../logo.png';
import printerBgImage from '../printer-bg.jpg';
import Header from './Header.tsx';
import Layout from './Layout.tsx';
import Footer from './Footer.tsx';
import PrintIcon from '@mui/icons-material/Print';
import CircularProgress from '@mui/joy/CircularProgress';
import { Alert, Stack } from '@mui/joy';
import Config from '../config';

export default function Homepage() {
  const [email, setEmail] = useState('');
  const [printerModalOpen, setPrinterModalOpen] = useState(false);
  const [printerUrl, setPrinterUrl] = useState('');
  const [printerWasAdded, setPrinterWasAdded] = useState(false);
  const [printerWasActivated, setPrinterWasActivated] = useState(false);

  const timestamp = new Date().getTime();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    await showPrinterModal();
  }

  function hidePrinterModal() {
    setPrinterModalOpen(false);
    setPrinterWasAdded(false);
  }

  async function showPrinterModal() {
    const res = await fetch(`${Config.apiUrl}/api/users/get-printer-url?email=${email}`);
    const url = await res.text();
    setPrinterUrl(url);
    setPrinterModalOpen(true);
  }

  async function getPrinterStatus() {
    const res = await fetch(`${Config.apiUrl}/api/users/check-printer-status?email=${email}`);
    return await res.json();
  }

  const handlePrinterAdded = async (event) => {
    setPrinterWasAdded(true);
    for (let i = 0; i < 60; i++) {
      const printerStatus = await getPrinterStatus();

      if (printerStatus && printerStatus.activatedAt > timestamp) {
        setPrinterWasActivated(true);
        break;
      }

      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  return (
    <CssVarsProvider>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Box
        sx={{
          overflowY: 'scroll',
          background: `url(${printerBgImage}) no-repeat`,
          backgroundSize: 'cover',
          scrollSnapType: 'y mandatory',
          '& > div': {
            scrollSnapAlign: 'start',
          },
        }}
      >
        <TwoSidedLayout reversed>
          <img src={logo} />
          <Typography
            level="h1"
            fontWeight="xl"
            fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
          >
            Printing made easy.
            No ink required.
          </Typography>
          <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
            Control-P compares online print services, magically finds you the best deal and allows you to print easily from any device.
          </Typography>
          {printerWasActivated && (
            <Box>
              <Alert variant="solid" color="success">
                Your printer has now been installed. Check your email for next steps.
              </Alert>
            </Box>
          )}
          {!printerWasActivated && (
            <Box
              onSubmit={handleSubmit}
              component="form"
              sx={{
                display: 'flex',
                gap: 1,
                my: 2,
                alignSelf: 'stretch',
                flexBasis: '80%',
              }}
            >
              <Input
                required
                name="email"
                type="email"
                size="lg"
                placeholder="Enter your email to add the Control-P virtual printer"
                sx={{ flex: 'auto' }}
                onChange={handleEmailChange}
              />
              <IconButton type="submit" size="lg" variant="solid" color="primary">
                <ArrowForward />
              </IconButton>
            </Box>
          )}
          <Typography
            level="body-xs"
            sx={{
              position: 'absolute',
              top: '2rem',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
          </Typography>
        </TwoSidedLayout>
      </Box>
      <Box>
        <Footer />
      </Box>

      <Modal
        open={printerModalOpen}
        onClose={hidePrinterModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '45%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: '#fff',
          border: '2px solid #000',
          borderRadius: '15px',
          boxShadow: 24,
          p: '20px 30px 30px',
        }}>
          <h2 id="modal-title">
            Welcome aboard!
          </h2>
          <p id="modal-description">
            We've generated you a unique link which will allow you to add the Control-P printer to your computer.
          </p>

          {!printerWasActivated && (
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              {!printerWasAdded && (
                <Button startDecorator={<PrintIcon />} component="a" href={printerUrl} onClick={handlePrinterAdded}>
                  Add printer
                </Button>
              )}
              {printerWasAdded && (
                <Button startDecorator={<CircularProgress />} component="a" href={printerUrl} onClick={handlePrinterAdded}>
                  Waiting for printer to be added
                </Button>
              )}
              <Button variant='outlined' onClick={hidePrinterModal}>
                Cancel
              </Button>
            </Box>
          )}
          {printerWasActivated && (
            <Box>
              <Alert variant="solid" color="success">
                Yay. Your printer has now been installed!
              </Alert>

              <p>
                Check your email for an email from <strong>printer@controlp.app</strong>. This contains a link to your printing dashboard. Click the link to login and to learn how to print.
              </p>

              <Button variant='outlined' onClick={hidePrinterModal}>
                Close this window
              </Button>
            </Box>
          )}
        </Box>
      </Modal>

    </CssVarsProvider>
  );
}