const Config = {
  get(key) {
    return process.env[`REACT_APP_${key}`];
  },

  get apiUrl() {
    return this.get('API_URL') || 'http://localhost:3000';
  },

  get apiKey() {
    return this.get('API_KEY');
  }
};

export default Config;