import * as React from 'react';
import { useState, useEffect } from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import { useNavigate } from 'react-router-dom';
import Sheet from '@mui/joy/Sheet';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Box from '@mui/joy/Box';
import Modal from '@mui/joy/Modal';
import Chip from '@mui/joy/Chip';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './components/TwoSidedLayout.tsx';
import logo from './logo.png';
import printerBgImage from './printer-bg.jpg';
import Header from './components/Header.tsx';
import Layout from './components/Layout.tsx';
import Footer from './components/Footer.tsx';
import PrintIcon from '@mui/icons-material/Print';
import CircularProgress from '@mui/joy/CircularProgress';
import { Alert, Stack } from '@mui/joy';
import Config from './config';
import Homepage from './components/Homepage';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginHandler from './components/LoginHandler';
import Dashboard from './components/Dashboard';
import PrintingServices from './components/PrintingServices';
import HowDoesItWork from './components/HowDoesItWork'; // Import the HowDoesItWork component
import { useSession } from './SessionContext';
import Order from './components/Order.js';

export default function App() {
  console.log(process.env)

  const { session, refreshSession } = useSession(); // Use context to check if the user is logged in

  useEffect(() => {
    let interval;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && window.location.pathname == '/dashboard') {
        refreshSession(); // Refresh session immediately when tab becomes active
        setupSessionRefresh();
      } else {
        clearInterval(interval); // Clear the interval when the tab is not active
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Initial setup
    if (document.visibilityState === 'visible' && window.location.pathname == '/dashboard') {
      refreshSession();
      setupSessionRefresh();
    }

    function setupSessionRefresh() {
      interval = setInterval(() => {
        refreshSession();
      }, 10000);
    }

    return () => {
      clearInterval(interval); // Clear the interval on component unmount
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <Router>
      <CssVarsProvider>
        <Routes>
          <Route 
            path="/"
            element={!session ? <Homepage /> : <Navigate to="/dashboard" replace />} 
          />
          <Route path="/login/:token" element={<LoginHandler />} />
          <Route path="/printing-services" element={<PrintingServices />} />
          <Route path="/how-does-it-work" element={<HowDoesItWork />} />
          <Route 
            path="/dashboard" 
            element={session ? <Dashboard /> : <Navigate to="/" replace />} 
          />
          <Route 
            path="/order" 
            element={session ? <Order /> : <Navigate to="/" replace />} 
          />
        </Routes>
      </CssVarsProvider>
    </Router>
  );
}