import * as React from 'react';
import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import { useLocation } from 'react-router-dom';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import PrintIcon from '@mui/icons-material/Print';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Divider } from '@mui/joy';

export default function Header() {
    const [open, setOpen] = React.useState(false);
    const location = useLocation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
            }}
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ display: { xs: 'none', sm: 'flex' } }}
            >
                <div style={{ "marginRight": "15px" }}>
                    <IconButton
                        size="lg"
                        variant="outlined"
                        color="neutral"
                        sx={{
                            display: { xs: 'none', sm: 'inline-flex' },
                            borderRadius: '50%'
                        }}
                    >
                        <PrintIcon />
                    </IconButton>
                </div>

                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    aria-pressed={location.pathname === '/' || location.pathname === '/dashboard'}
                    href="/"
                    size="lg"
                    sx={{ alignSelf: 'center' }}
                >
                    My Home
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    aria-pressed={location.pathname === '/printing-services'}
                    href="/printing-services"
                    size="lg"
                    sx={{ alignSelf: 'center' }}
                >
                    Printing Services
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    aria-pressed={location.pathname === '/how-does-it-work'}
                    href="/how-does-it-work"
                    size="lg"
                    sx={{ alignSelf: 'center' }}
                >
                    How does it work?
                </Button>
            </Stack>
            <Box sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
                <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
                    <MenuRoundedIcon />
                </IconButton>
                <Drawer
                    sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <ModalClose />
                    <DialogTitle>Acme Co.</DialogTitle>
                    <Box sx={{ px: 1 }}>

                    </Box>
                </Drawer>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1.5,
                    alignItems: 'center',
                }}
            >
                <Input
                    size="md"
                    variant="outlined"
                    placeholder="Ask for help..."
                    startDecorator={<HelpRoundedIcon color="primary" />}
                    endDecorator={
                        <IconButton
                            variant="outlined"
                            color="neutral"
                            sx={{ bgcolor: 'background.level1' }}
                        >
                            <Typography level="title-sm" textColor="text.icon">
                                ↵
                            </Typography>
                        </IconButton>
                    }
                    sx={{
                        alignSelf: 'center',
                        display: {
                            xs: 'none',
                            sm: 'flex',
                        },
                    }}
                />
                <IconButton
                    size="lg"
                    variant="outlined"
                    color="neutral"
                    sx={{ display: { xs: 'inline-flex', sm: 'none' }, alignSelf: 'center' }}
                >
                    <SearchRoundedIcon />
                </IconButton>
                <Dropdown>
                    <MenuButton
                        variant="plain"
                        size="lg"
                        sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
                    >
                        <Avatar
                            src="https://0.gravatar.com/avatar/53afe70274f5154dab0f96bdd4d48215c5ca89895363c3e3cd93472a88859339?size=512"
                            srcSet="https://0.gravatar.com/avatar/53afe70274f5154dab0f96bdd4d48215c5ca89895363c3e3cd93472a88859339?size=512"
                            sx={{ maxWidth: '40px', maxHeight: '40px' }}
                        />
                    </MenuButton>
                    <Menu
                        placement="bottom-end"
                        size="sm"
                        sx={{
                            zIndex: '99999',
                            p: 1,
                            gap: 1,
                            '--ListItem-radius': 'var(--joy-radius-sm)',
                        }}
                    >
                        <MenuItem>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar
                                    src="https://0.gravatar.com/avatar/53afe70274f5154dab0f96bdd4d48215c5ca89895363c3e3cd93472a88859339?size=512"
                                    srcSet="https://0.gravatar.com/avatar/53afe70274f5154dab0f96bdd4d48215c5ca89895363c3e3cd93472a88859339?size=512"
                                    sx={{ borderRadius: '50%' }}
                                />
                                <Box sx={{ ml: 1.5 }}>
                                    <Typography level="title-sm" textColor="text.primary">
                                        Marcus Greenwood
                                    </Typography>
                                    <Typography level="body-xs" textColor="text.tertiary">
                                        mg@ub.io
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                        <ListDivider />
                        <MenuItem>
                            <HelpRoundedIcon />
                            Help
                        </MenuItem>
                        <MenuItem>
                            <SettingsRoundedIcon />
                            My account
                        </MenuItem>
                        <ListDivider />
                        <MenuItem>
                            <LogoutRoundedIcon />
                            Log out
                        </MenuItem>
                    </Menu>
                </Dropdown>
            </Box>
        </Box>
    );
}