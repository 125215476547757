import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSession } from '../SessionContext';

const LoginHandler = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { validateToken, error } = useSession();

  useEffect(() => {
    const handleTokenValidation = async () => {
      const isValid = await validateToken(token);
      if (isValid) {
        navigate('/dashboard');
      }
    };

    handleTokenValidation();
  }, [token, validateToken, navigate]);

  if (error) {
    return <div>{error}</div>;
  }

  return <div>Logging in...</div>;
};

export default LoginHandler;