import React from 'react';
import { useState, useEffect } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import { useSession } from '../SessionContext.js';
import Header from './Header.tsx';
import Footer from './Footer.tsx';
import Layout from './Layout.tsx';
import { Card, IconButton, AspectRatio, CardContent, Stack, Select, Option, Grid, Container, CircularProgress, FormControl, FormLabel, Input, Divider, Checkbox, Modal, ModalDialog, ModalClose, LinearProgress } from '@mui/joy';
import { BookmarkAdd, DeleteOutline, Print, FileUpload, Image, EmailRounded, Celebration } from '@mui/icons-material';
import moment from 'moment';
import OrientedImage from './OrientedImage.js';
import { OpenCvProvider } from 'opencv-react';
import { styled } from '@mui/system';


import bootsLogo from '../images/boots-logo.svg';
import snapfishLogo from '../images/snapfish-logo.png';
import PolaroidPile from './PolaroidPile.jsx';

const PlaceOrder = ({ jobId }) => {
    const { session } = useSession();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [completed, setCompleted] = useState(false);

    let progressInterval;

    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    useEffect(async () => {
        const printjobResponse = await fetch(`https://us157beq.run.nodescript.dev/api/jobs/execute`, { method: 'POST', body: JSON.stringify({ jobId: jobId }), headers: { 'Content-Type': 'application/json' } });
        const printjob = await printjobResponse.json();

        if (!progressInterval) {
            progressInterval = setInterval(async () => {
                const response = await fetch(`https://us157beq.run.nodescript.dev/api/jobs/get-progress?jobId=${printjob.id}`);
                const data = await response.json();
                setProgress(data.progress);
                if (data.progress === 100) {
                    setCompleted(true);
                    clearInterval(progressInterval);
                }
            }, 1000);
        }

        return () => {
            clearInterval(progressInterval);
        };
    }, []);

    return (
        <Modal open={true}>
            <ModalDialog size="lg" sx={{ width: '500px' }}>
                {completed && (
                    <>
                        <Typography typography={{ md: 'h2' }}>Order complete <Celebration /></Typography>
                        <Typography>Your order has been placed with <b>Boots Photo</b>. Please check your email for confirmation and delivery details.</Typography>
                        <Typography>
                            <a href="/past-orders">Visit your orders dashboard</a>
                        </Typography>
                    </>
                )}
                {!completed && (
                    <>
                        <Typography typography={{ md: 'h2' }}>Placing order</Typography>
                        <Typography>Please wait while we process your order with <b>Boots Photo</b></Typography>
                        <SmoothLinearProgress determinate value={progress} />
                    </>
                )}
            </ModalDialog>
        </Modal>
    );
};

const SmoothLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '& .MuiLinearProgress-bar': {
        transition: 'transform 0.5s ease-in-out',
    },
}));

export default PlaceOrder;
