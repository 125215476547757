import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Container from '@mui/joy/Container';
import { typographyClasses } from '@mui/joy/Typography';
import PolaroidPile from './PolaroidPile';

const images:any = [];
for (let i=0; i<36; i++) {
  images.push({
    src: 'https://unsplash.it/500/750?random&r=' + Math.floor(Math.random() * 100),
    orientation: ['landscape', 'portrait', 'square'][Math.floor(Math.random() * 3)]
  });
}

export default function TwoSidedLayout({
  children,
  reversed,
}: React.PropsWithChildren<{ reversed?: boolean }>) {
  return (
    <Container
      sx={(theme) => ({
        height: '1100px',
        position: 'relative',
        display: 'flex',
        flexDirection: reversed ? 'column-reverse' : 'column',
        alignItems: 'center',
        gap: '2rem',
        [theme.breakpoints.up(834)]: {
          flexDirection: 'row',
          gap: 12,
        },
        [theme.breakpoints.up(1199)]: {
          gap: 18,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
          maxWidth: '50ch',
          textAlign: 'center',
          flexShrink: 999,
          [theme.breakpoints.up(834)]: {
            minWidth: 420,
            alignItems: 'flex-start',
            textAlign: 'initial',
          },
          [`& .${typographyClasses.root}`]: {
            textWrap: 'balance',
          },
        })}
      >
        {children}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
          marginTop: '-12rem',
          maxWidth: '50ch',
          textAlign: 'center',
          flexShrink: 999,
          [theme.breakpoints.up(834)]: {
            minWidth: 420,
            alignItems: 'flex-start',
            textAlign: 'initial',
          },
          [`& .${typographyClasses.root}`]: {
            textWrap: 'balance',
          },
        })}
      >
        <PolaroidPile images={images} containerWidth={550} containerHeight={600} squashFactor={1.1} maxAngle={30} />
      </Box>
    </Container>
  );
}