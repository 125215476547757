import * as React from 'react';
import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Item from '@mui/joy/ListItem';
import Avatar from '@mui/joy/Avatar';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import { Grid } from '@mui/joy';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

export default function Footer() {
  const [open, setOpen] = React.useState(false);
  return (
    <footer>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'space-between',
        }}
      >
        <Grid container spacing={0} sx={{ flexGrow: 1, bgcolor: 'var(--joy-palette-background-surface)', padding: '2.5rem', borderTop: 'solid 1px var(--joy-palette-divider)' }}>
          <Grid xs>
            <Stack spacing={1}>
              <Item><strong>Company</strong></Item>
              <Item><a href="/how-does-it-work">How does Control-P work?</a></Item>
              <Item><a href="/about">About us</a></Item>
            </Stack>
          </Grid>
          <Grid xs>
            <Stack spacing={1}>
              <Item><strong>Legal</strong></Item>
              <Item><a href="/terms">Terms of Service</a></Item>
              <Item><a href="/privacy">Privacy Policy</a></Item>
              <Item><a href="/cookies">Cookie Policy</a></Item>
            </Stack>
          </Grid>
          <Grid xs>
            <Stack spacing={1}>
              <Item><strong>Resources</strong></Item>
              <Item><a href="/blog">Blog</a></Item>
              <Item><a href="/help">Help</a></Item>
              <Item><a href="/faqs">FAQs</a></Item>
            </Stack>
          </Grid>
          <Grid xs>

          </Grid>
        </Grid>
      </Box>
    </footer>
  );
}
