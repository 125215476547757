// PolaroidPile.js

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';

const serverUrl = process.env.REACT_APP_SERVER_URL;

console.log(serverUrl)

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: visible; /* Make overflow visible */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Polaroid = styled(motion.div)`
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 10px solid white;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SquarePolaroid = styled(Polaroid)`
  padding-bottom: 40px; /* Additional padding for square images */
`;

const getImageUrl = (url, width) => {
  console.log(serverUrl)
  return `${serverUrl}/api/image?url=${encodeURIComponent(url)}&width=${width}`;
};

const getPolaroidDimensions = (orientation, multiplier = 1) => {
  switch (orientation) {
    case 'landscape':
      return { width: 250 * multiplier, height: 200 * multiplier };
    case 'portrait':
      return { width: 200 * multiplier, height: 250 * multiplier };
    case 'square':
      return { width: 175 * multiplier, height: 175 * multiplier }; // Update dimensions for square images
    default:
      return { width: 200 * multiplier, height: 250 * multiplier };
  }
};

const PolaroidPile = ({ images, containerWidth = 800, containerHeight = 600, squashFactor = 2, maxAngle = 45, animate = true, multiplier = 1 }) => {
  const [loaded, setLoaded] = useState(false);
  const controls = useAnimation();
  const columns = 4; // Number of columns in the grid
  const rows = Math.ceil(images.length / columns); // Number of rows needed

  useEffect(() => {
    const imagePromises = images.map(
      (img) =>
        new Promise((resolve) => {
          const image = new Image();
          image.src = img.src;
          image.onload = resolve;
        })
    );

    Promise.all(imagePromises).then(() => {
      setLoaded(true);
    });
  }, [images]);

  useEffect(() => {
    if (loaded) {
      controls.start((i) => {
        const col = i % columns;
        const row = Math.floor(i / columns);
        const { width: imgWidth, height: imgHeight } = getPolaroidDimensions(images[i].orientation);

        // Calculate grid position with some randomness, ensuring the image center stays within the container
        const x = (col * (containerWidth / columns) / squashFactor) - containerWidth / (2 * squashFactor) + imgWidth / 2 + (Math.random() * 40 - 20);
        const y = (row * (containerHeight / rows) / squashFactor) - containerHeight / (2 * squashFactor) + imgHeight / 2 + (Math.random() * 40 - 20);

        return {
          y: [-containerHeight / 2, y],
          x: [0, x],
          rotate: [0, Math.random() * maxAngle - maxAngle / 2], // Random rotation between -maxAngle and maxAngle
          scale: [1.5, Math.random() * 0.3 + 0.5],
          opacity: [0, 1],
          transition: animate && {
            y: { duration: 0.8, ease: 'easeOut' },
            x: { duration: 0.8, ease: 'easeOut' },
            rotate: { duration: 0.8, ease: 'easeOut' },
            scale: { duration: 0.8, ease: 'easeOut' },
            opacity: { duration: 0.8, ease: 'easeOut' },
          },
        };
      });
    }
  }, [controls, loaded, containerWidth, containerHeight, images, columns, rows, squashFactor, maxAngle]);

  return (
    <Container style={{ height: containerHeight + 'px' }}>
      {images.map((img, index) => {
        const { src, orientation } = img;
        const { width: imgWidth, height: imgHeight } = getPolaroidDimensions(orientation, multiplier);
        const PolaroidComponent = orientation === 'square' ? SquarePolaroid : Polaroid;

        return (
          <PolaroidComponent
            key={index}
            custom={index}
            initial={{ y: -containerHeight / 2, x: 0, rotate: 0, scale: 1.5, opacity: 0 }}
            animate={controls}
            style={{ width: `${imgWidth}px`, height: `${imgHeight}px` }}
          >
            <img src={getImageUrl(src, 600)} alt={`Polaroid ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </PolaroidComponent>
        );
      })}
    </Container>
  );
};

export default PolaroidPile;
