import React, { createContext, useState, useContext, useEffect } from 'react';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(() => {
    const savedSession = localStorage.getItem('session');
    return savedSession ? JSON.parse(savedSession) : null;
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    localStorage.setItem('session', JSON.stringify(session));
  }, [session]);

  const login = (userData) => {
    setSession(userData);
  };

  const logout = () => {
    setSession(null);
    localStorage.removeItem('session');
  };

  const refreshSession = async () => {
    let savedSession = localStorage.getItem('session');
    if (savedSession !== null && savedSession !== 'null') {
      savedSession = JSON.parse(savedSession);
      await validateToken(savedSession.tokenId);
    }
  };

  const validateToken = async (token) => {
    try {
      const response = await fetch(`https://us157beq.run.nodescript.dev/api/session/get?tokenId=${token}`);
      if (response.ok) {
        const sessionData = await response.json();
        login(sessionData);
        return true;
      } else {
        setError('Invalid or expired token');
        return false;
      }
    } catch (err) {
      setError('An error occurred while validating the token');
      return false;
    }
  };

  return (
    <SessionContext.Provider value={{ session, login, logout, refreshSession, validateToken, error }}>
      {children}
    </SessionContext.Provider>
  );
};

const fetchSessionFromAPI = async () => {
  return await fetch('/api/refresh-session').then(res => res.json());
};

export const useSession = () => useContext(SessionContext);